@import 'wysiwyg-content.scss';

@import 'public.breadcrumbs.scss';
@import 'public.series.scss';

.carousel-item {
  //max-height:300px;
  overflow: hidden;
}

.carousel-image {
  width:100%;
}

.homepage-welcome {
  padding: 2em 0 1.5em 0;
  margin: 0 0 1em 0;
  font-size: 1.2em;
  background-image: url('/staticImages/old_moon.png');
  background-color: rgba(255,255,255,.5);
}

footer {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  margin-top: 2em;
  padding: 1em;
  a:hover {
    text-decoration: none;
  }
}

.navbar {
  margin-bottom: 0;
}

body {
  font-family: 'Asap', sans-serif;
}
.navbar-brand {
  font-family: 'Philosopher', sans-serif;
  font-size: 2.5em;
}
.series-name {
  font-family: 'Philosopher', sans-serif;
  font-size: 1.5em;
}
.special-name {
  font-family: 'Philosopher', sans-serif;
  font-size: 1.0em;
}
.gallery-title {
  font-family: 'Philosopher', sans-serif;
  font-size: 1.8em;

}

.happy-block {
  background: rgba(255, 255, 255, .5) url('/staticImages/old_moon.png');
}

.carousel-container {
  padding:0;
}

.works img {
  vertical-align: top;
}


.receives-height {
  display:block;
  overflow:hidden;
}

.photos-list .photos {
  margin-top: 1em;
  img {
    margin:auto;
  }
}