ul.breadcrumbs {

  margin:0;
  padding:0;
  list-style:none;
  li {
    list-style: none;
    display:inline-block;
  }
  li:after {
    content: " \27A4 ";
  }
  li:last-child:after {
    content: "";
  }

}