

.work {
  .name {
    //font-size: 72px;
  }
  .related-series-name {
    font-size: 22px;
  }
  .details {
    font-size: 18px;
  }
}
.related-series {
  margin-top: 1em;
  .works {
    margin-top: 1em;
    //display: inline-block;
    //width: 210px;
    //text-align: center;
    //margin: 4px 4px 2em 4px;
    //img {
    //  max-width: 200px;
    //  max-height:200px;
    //  margin: 2px auto;
    //}
  }
  .works.col-xs-12 {
    text-align:center;
    img {
      margin: auto;
    }
  }
}
